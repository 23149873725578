import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link-main"
          href="https://rajesh.lol"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
        >
          Welcome to the Edge of Reality, Click to begin        
        </a>                    
        <a
          className="App-link"
          href="https://mylinks.rajesh.lol"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
        >
          Do not click this!        
        </a>
      </header>
    </div>
  );
}

export default App;
